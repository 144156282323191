// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek1 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649088/Slide_16_9_-_93_nnkdkv.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649087/Slide_16_9_-_94_gqv0bc.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649086/Slide_16_9_-_95_nnyh34.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649084/Slide_16_9_-_96_wxwkqi.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649084/Slide_16_9_-_97_r2rbzx.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649085/Slide_16_9_-_98_g7ai3l.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649085/Slide_16_9_-_99_ckm4kj.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649085/Slide_16_9_-_100_ibk0xj.png',

  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek1;

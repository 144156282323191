import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Navbar from "../components/Navbar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Waiting from "./Waiting";
import WaitingSub from "./WaitingSub";

// import Sidebar from "../components/Sidebar";
// import NavInner from "../components/NavInner";
// import StudentWebNav from "../components/StudentWebNav";

const Weeks = () => {
  let { user } = useContext(AuthContext);
  const [isFetching, setisFetching] = useState(true);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // extract profile id
  let params = useParams();
  let profileID = user["user_id"];
  const pathway_name = "Leadership";
  const pathway_id = 1;
  const [pathway_progress, setPathProgress] = useState({});
  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [weeks, setWeeks] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`, config);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
        console.log("PRO:  ", profileID);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    const fetchProgress = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/pathway_progress/${profileID}/${pathway_id}/`, config);
        let data = await response.json();
        setisFetching(false);
        console.log(data);

        setPathProgress(data);
      } catch (err) { }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchProgress();
    }, 2000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);

  const fetchWeeks = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/weeks/`, config); // Update the API endpoint based on your project's URL structure
      const data = await response.json();
      console.error("Weeks", data);
      setWeeks(data);
    } catch (error) {
      console.error("Error fetching weeks:", error);
    }
  };

  useEffect(() => {
    fetchWeeks();
  }, []);

  const [scroll, setScroll] = useState(false);
  console.log(pathway_progress);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "complete";
      case "Active":
        return "primary";
      case "Locked":
        return "locked";
      default:
        return "default"; // Set a default class or handle other cases as needed
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getSubById, setgetSubById] = useState({});

  const [isMySubModalOpen, setIsMySubModalOpen] = useState(false);
  const [getMySubById, setgetMySubById] = useState({});
  const [rating, setRating] = useState(0);

  const [feedback, setFeedback] = useState("");
  const [subFeedback, setSubFeedback] = useState("");

  const openModal = (item) => {
    setIsModalOpen(true);
    setgetSubById(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setgetSubById({});
  };

  const openMyModal = (item) => {
    setIsMySubModalOpen(true);
    setgetMySubById(item);
  };

  const closeMyModal = () => {
    setIsMySubModalOpen(false);
    setgetMySubById({});
  };

  const [isFlag, setIsFlag] = useState(false);

  const handleCheckboxChange = () => {
    setIsFlag((prevState) => !prevState);
  };

  const handleSubmitSubFeedback = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`, {
        user_id: profileID,
        peerid: getMySubById.user_id,
        pathway: pathway_id,
        week_id: getMySubById.week_id,
        subFeedback: subFeedback,
        rating: rating,
        flag: isFlag,
        id: getMySubById.id,
      }, config)
      .then((res) => {
        toast.success("Sub Feedback Submitted Successfully");
        closeMyModal();
      })
      .catch((err) => {
        toast.error("Error in Submitting Sub Feedback!");
        closeMyModal();
      });
  };

  const handleSubmitFeedback = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
        user_id: profileID,
        peerid: getSubById.user_id,
        pathway: pathway_id,
        week_id: getSubById.week_id,
        feedback: feedback,
        status: "Complete",
        id: getSubById.id,
      }, config)
      .then((res) => {
        toast.success("Feedback Submitted Successfully");
        fetchAssignedSubmission();
        closeModal();
        // setweek2check(true)
      })
      .catch((err) => {
        toast.error("Error in Submitting Feedback!");
        closeModal();
      });
  };

  const [mySubmissions, setMySubmissions] = useState([]);
  const [assignedSubmission, setAssignedSubmission] = useState([]);
  const [week_id, setWeek_id] = useState(0);

  const fetchMySubmission = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/api/get_user_submission/${profileID}/`, config)
      .then((res) => {
        const data = res.data;
        const filteredData = data.filter((item) => item.pr_enabled && item.feedback !== null);
        setMySubmissions(filteredData);
      })
      .catch((err) => {
        console.log("Error in fetching my submission!!", err);
      });
  };

  const [week1check, setweek1check] = useState(false);
  const [week2check, setweek2check] = useState(false);

  const [week4check, setweek4check] = useState(false);
  const [week5check, setweek5check] = useState(false);
  const [week6check, setweek6check] = useState(false);
  const [week7check, setweek7check] = useState(false);
  const [dataFinder, setdataFinder] = useState(false);
  const fetchAssignedSubmission = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/${profileID}/`, config)
      .then((res) => {
        const data = res.data;

        const filteredData = data.filter((item) => item.pr_enabled);

        // console.warn('asigned',data)
        // let clean = data.content.replace(/^"|"$/g, "").replace(/\\n/g, "\n");
        // console.log("CLEAN: ", clean);
        // setCleanData(clean);
        setAssignedSubmission(filteredData);



        // if ((filteredData?.week_id === 2 && (filteredData?.pr_enabled || data?.pr_enabled === true) && (filteredData?.feedback === null || filteredData?.feedback === "")).length>0 ) {
        //   console.warn(`data if: `,filteredData)
        //   setweek2check(false);

        // } else {
        //   console.warn(`data else: `,filteredData)
        //   setweek2check(true);

        // }

        // Assuming filteredData is your array of submissions
        let hasNullFeedback = filteredData.some(submission => submission.feedback === null && submission.week_id === 2 && submission.status === "Reviewing");
        let resultJSON = filteredData(submission => submission.feedback === null && submission.week_id === 2 && submission.status === "Reviewing");
        // Set the state based on the condition
        console.warn(`data else: `, resultJSON)
        setweek2check(hasNullFeedback);
        setdataFinder(true);


        console.log("CLEAN: ", data);
        // const isWeek1 = data.some(item => item.assigned_to === profileID && item.week_id - 1 === 1);
        const isWeek1 = data.some(
          (item) =>
            item.assigned_to === profileID &&
            item.week_id - 1 === 1 &&
            item.pr_enabled === true &&
            (item.feedback === null || item.feedback === "")
        );
        const isWeek2 = data.some(
          (item) =>
            item.assigned_to === profileID &&
            item.week_id - 1 === 2 &&
            item.pr_enabled === true &&
            (item.feedback === null || item.feedback === "")
        );
        const isWeek4 = data.some(
          (item) =>
            item.assigned_to === profileID &&
            item.week_id - 1 === 4 &&
            item.pr_enabled === true &&
            (item.feedback === null || item.feedback === "")
        );
        const isWeek5 = data.some(
          (item) =>
            item.assigned_to === profileID &&
            item.week_id - 1 === 5 &&
            item.pr_enabled === true &&
            (item.feedback === null || item.feedback === "")
        );
        const isWeek6 = data.some(
          (item) =>
            item.assigned_to === profileID &&
            item.week_id - 1 === 6 &&
            item.pr_enabled === true &&
            (item.feedback === null || item.feedback === "")
        );
        const isWeek7 = data.some(
          (item) =>
            item.assigned_to === profileID &&
            item.week_id - 1 === 7 &&
            item.pr_enabled === true &&
            (item.feedback === null || item.feedback === "")
        );

        // setweek1check(!isWeek1);
        // setweek2check(!isWeek2);
        // setweek4check(!isWeek4);
        // setweek5check(!isWeek5);
        // setweek6check(!isWeek6);
        // setweek7check(!isWeek7);



        console.warn("Week2CHECK: " + week2check);
        // console.log("WEEK: ", data.week_id);
        // console.log("ASSIGN: ", assignedSubmission);
      })
      .catch((err) => {
        // alert("Error in fetching my submission!!", err);
      });
  };

  function processData(content) {
    try {
      const contentJSON = JSON.parse(content);
      if (typeof contentJSON === "object" && contentJSON !== null) {
        const keyValuePairs = Object.entries(contentJSON).map(
          ([key, value]) => `${key}: ${value}`
        );
        return keyValuePairs.join("\r\n");
      } else {
        return contentJSON;
      }
    } catch (error) {
      return content;
    }
  }

  useEffect(() => {
    fetchMySubmission();
    fetchAssignedSubmission();
  }, [profileID]);

  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in the format 'YYYY-MM-DD'

  console.warn("PENDING: ", assignedSubmission);

  return (
    <div>
      <div>
        <div>
          <div>
            <>
              <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

              {/* <Sidebar /> */}

              {/* {isSidebarVisible && <SideInnerSM/>} */}

              <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
                {/* Navbar */}
                <Navbar />

                <div
                  className="flex-col-reverse gap-6 md:gap-0 md:flex-row flex flex-wrap mt-6 px-2 md:px-12"
                  data-tour="step2"
                >
                  <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-8/12 lg:flex-none">
                    <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                      {/* Heading */}
                      <div className="flex-auto p-4">
                        <div className="w-full mx-auto">
                          <div className=" bg-white w-full rounded-lg ">
                            {isFetching ? (
                              <div className="flex flex-col justify-center gap-4 w-full">
                                <div className="skeleton h-32 w-full"></div>
                                <div className="skeleton h-4 w-28"></div>
                                <div className="skeleton h-4 w-full"></div>
                                <div className="skeleton h-4 w-full"></div>
                              </div>
                            ) : (
                              <div className="">
                                <div className="relative col-span-12 space-y-6 sm:col-span-9">
                                  <div className="col-span-12 space-y-12 relative sm:col-span-8 sm:space-y-8">
                                    <div className="">
                                      <div className="">
                                        <div className="p-4">
                                          <h1 className="text-lg text-start text-primary font-semibold m-0">
                                            Ascent Leadership Program
                                          </h1>
                                          <p className="text-base text-start mb-2">
                                            {`${weeks.filter(
                                              (week) =>
                                                pathway_progress[
                                                  week.week_name
                                                ]?.status === "Completed"
                                            ).length
                                              } COMPLETED / `}
                                            <span className="text-primary">{`${weeks.length -
                                              weeks.filter(
                                                (week) =>
                                                  pathway_progress[
                                                    week.week_name
                                                  ]?.status === "Completed"
                                              ).length
                                              }-TO-DO`}</span>
                                          </p>
                                        </div>
                                        <div className="flex flex-col md:grid grid-cols-12 text-gray-50">
                                          {weeks.map((week) => (
                                            <div
                                              className="flex md:contents"
                                              key={week.id}
                                            >
                                              <div className="col-start-1 col-end-1 mr-10 md:mx-auto relative">
                                                <div className="h-full w-6 flex items-center justify-center">
                                                  <div className="h-full w-1 bg-gray-300 pointer-events-none"></div>
                                                </div>

                                                {(() => {
                                                  if (
                                                    pathway_progress[
                                                      week.week_name
                                                    ]?.status === "Active"
                                                  ) {
                                                    return (
                                                      <div className="w-6 h-6 absolute top-1/2 flex justify-center items-center rounded-full bg-primary shadow text-center">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          stroke-width="1.5"
                                                          stroke="currentColor"
                                                          class="w-5 h-5 text-white"
                                                        >
                                                          <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                          />
                                                        </svg>
                                                      </div>
                                                    );
                                                  } else if (
                                                    pathway_progress[
                                                      week.week_name
                                                    ]?.status === "Completed"
                                                  ) {
                                                    return (
                                                      <div className="w-6 h-6 absolute top-1/2 flex justify-center items-center rounded-full bg-complete shadow text-center">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          stroke-width="1.5"
                                                          stroke="currentColor"
                                                          class="w-5 h-5 text-white"
                                                        >
                                                          <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                          />
                                                        </svg>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div className="w-6 h-6 absolute top-1/2 flex justify-center items-center rounded-full bg-locked shadow text-center">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          stroke-width="1.5"
                                                          stroke="currentColor"
                                                          class="w-4 h-4 text-primary"
                                                        >
                                                          <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                          />
                                                        </svg>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div
                                                className={`col-start-2 col-end-12 rounded-xl my-4 mr-auto shadow-md w-full ${pathway_progress[
                                                    week.week_name
                                                  ]?.status === "Locked"
                                                    ? "opacity-30"
                                                    : ""
                                                  }`}
                                              >
                                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center p-4 bg-white rounded-t-lg">
                                                  <div>
                                                    <img
                                                      className="h-32 w-auto mx-auto"
                                                      src={week?.image}
                                                    />
                                                  </div>

                                                  <div className="col-span-2">
                                                    <p className="text-primary sm:m-0 md:mb-4 font-medium text-sm">
                                                      {/* {week?.week_name} */}
                                                      {`WEEK ${week.week_name.substring(
                                                        5
                                                      )}`}
                                                    </p>
                                                    <h3 className="font-semibold text-black text-lg mb-1">
                                                      {week?.title}{" "}
                                                      {/* {
                                                        pathway_progress[
                                                          week.week_name
                                                        ]?.due_date
                                                      } */}
                                                    </h3>
                                                    <p className="leading-tight text-sm text-gray-400 text-start w-full">
                                                      {truncateText(
                                                        week?.content,
                                                        80
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div
                                                  className={`bg-${getStatusColor(
                                                    pathway_progress[
                                                      week.week_name
                                                    ]?.status
                                                  )} p-4 rounded-b-lg`}
                                                >
                                                  <div className="flex justify-between flex-col gap-2  md:gap-0  md:flex-row items-center">
                                                    <div className="flex flex-row gap-2 items-center">
                                                      <button className="btn btn-sm btn-circle bg-transparent border-0">
                                                        {(() => {
                                                          if (
                                                            pathway_progress[
                                                              week.week_name
                                                            ]?.status ===
                                                            "Active"
                                                          ) {
                                                            return (
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-6 h-6 text-white"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                />
                                                              </svg>
                                                            );
                                                          } else if (
                                                            pathway_progress[
                                                              week.week_name
                                                            ]?.status ===
                                                            "Completed"
                                                          ) {
                                                            return (
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-6 h-6 text-white"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                />
                                                              </svg>
                                                            );
                                                          } else {
                                                            return (
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-6 h-6 text-primary"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                                />
                                                              </svg>
                                                            );
                                                          }
                                                        })()}
                                                      </button>

                                                      <span>
                                                        {
                                                          pathway_progress[
                                                            week.week_name
                                                          ]?.status
                                                        }
                                                      </span>
                                                    </div>

                                                    {(() => {
                                                      if (
                                                        new Date()
                                                          .toISOString()
                                                          .split("T")[0] <
                                                        week?.due_date
                                                      ) {
                                                        return (
                                                          <div>
                                                            {(() => {
                                                              if (
                                                                pathway_progress[
                                                                  week.week_name
                                                                ]?.status ===
                                                                "Completed"
                                                              ) {
                                                                return (
                                                                  <Link
                                                                    to={`/${week?.week_name}`}
                                                                    state={{
                                                                      server_progress:
                                                                        pathway_progress[
                                                                        `${week?.week_name}`
                                                                        ],
                                                                    }}
                                                                  >
                                                                    <button
                                                                      type="button"
                                                                      className="btn gap-2 btn-sm px-4 py-1 rounded-full border-0 bg-white text-complete"
                                                                    >
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        fill="currentColor"
                                                                        className="w-6 h-6"
                                                                      >
                                                                        <path
                                                                          fill-rule="evenodd"
                                                                          d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                                          clip-rule="evenodd"
                                                                        />
                                                                      </svg>
                                                                      View again
                                                                    </button>
                                                                  </Link>
                                                                );
                                                              } else if (
                                                                pathway_progress[
                                                                  week.week_name
                                                                ]?.status ===
                                                                "Active"
                                                              ) {
                                                                return (
                                                                  <>
                                                                    {/* {(() => {
                                                                              if (
                                                                               
                                                                                week?.id===3 && (week2check===false || !week2check)
                                                                              ) {
                                                                                return (
                                                                                  
                                                                                );
                                                                              } else {
                                                                                return (
                                                                                  <div className="badge badge-lg badge-error text-white">
                                                                                    Complete
                                                                                    Peer
                                                                                    Review
                                                                                  </div>
                                                                                );
                                                                              }
                                                                            })()} */}

                                                                    <Link
                                                                      to={`/${week?.week_name}`}
                                                                      state={{
                                                                        server_progress:
                                                                          pathway_progress[
                                                                          `${week?.week_name}`
                                                                          ],
                                                                      }}
                                                                    >
                                                                      <button
                                                                        type="button"
                                                                        className="btn gap-2 btn-sm px-4 py-1 rounded-full border-0 bg-white text-active"
                                                                      >
                                                                        <svg
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          viewBox="0 0 24 24"
                                                                          fill="currentColor"
                                                                          class="w-6 h-6"
                                                                        >
                                                                          <path
                                                                            fill-rule="evenodd"
                                                                            d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                                                                            clip-rule="evenodd"
                                                                          />
                                                                        </svg>
                                                                        Start
                                                                        Now
                                                                      </button>
                                                                    </Link>
                                                                  </>
                                                                );
                                                              }
                                                            })()}
                                                          </div>
                                                        );
                                                      } else if (
                                                        new Date()
                                                          .toISOString()
                                                          .split("T")[0] >
                                                        week?.due_date &&
                                                        pathway_progress[
                                                          week.week_name
                                                        ]?.status !== "Locked"
                                                      ) {
                                                        return (
                                                          <>
                                                            <span class="btn btn-ghost gap-2 btn-sm px-4 py-1 rounded-full border-0 text-white">
                                                              This week is
                                                              locked 🔒
                                                            </span>
                                                            {/* <Link
                                                                    to={`/${week?.week_name}`}
                                                                    state={{
                                                                      server_progress:
                                                                        pathway_progress[
                                                                          `${week?.week_name}`
                                                                        ],
                                                                    }}
                                                                  >
                                                                    <button
                                                                      type="button"
                                                                      className="btn gap-2 btn-sm px-4 py-1 rounded-full border-0 bg-white text-active"
                                                                    >
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        fill="currentColor"
                                                                        class="w-6 h-6"
                                                                      >
                                                                        <path
                                                                          fill-rule="evenodd"
                                                                          d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                                                                          clip-rule="evenodd"
                                                                        />
                                                                      </svg>
                                                                      Start Now
                                                                    </button>
                                                                  </Link> */}
                                                          </>
                                                        );
                                                      }
                                                    })()}

                                                    {/* {(() => {
  console.log("Current Date:", new Date().toISOString().split("T")[0]);
  console.log("Due Date:", week?.due_date);

  if (
    new Date().toISOString().split("T")[0] < pathway_progress[week.week_name]?.due_date
  ) {
    console.log("Week is active.");
    return (
      <div>
        OK
      </div>
    );
  } else if (
    new Date().toISOString().split("T")[0] > pathway_progress[week.week_name]?.due_date &&
    pathway_progress[week.week_name]?.status !== "Locked"
  ) {
    console.log("Due date has passed.");
    return (
      <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-500 text-white">
        Due date has passed
      </span>
    );
  }
})()} */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {(() => {
                                  if (
                                    weeks.filter(
                                      (week) =>
                                        pathway_progress[week.week_name]
                                          ?.status === "Completed"
                                    ).length === weeks.length &&
                                    week7check
                                  ) {
                                    return (
                                      <div className="fixed bottom-0 end-0 z-[60] sm:max-w-sm w-full mx-auto p-6">
                                        {/* Card */}
                                        <div className="p-4 bg-gradient-to-br from-violet-700 to-sky-500 rounded-xl shadow-sm darki:bg-gray-800 darki:border-gray-700">
                                          <h3 className="text-lg text-white">
                                            {" "}
                                            Congratulations 🥳
                                          </h3>
                                          <p className="mt-2 text-sm text-white">
                                            You've completed the ascent
                                            leadership program
                                          </p>
                                          <div className="mt-5 flex justify-between gap-x-2">
                                            <Link
                                              to="/cert"
                                              type="button"
                                              class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                                            >
                                              Download Certificate
                                            </Link>
                                          </div>
                                        </div>
                                        {/* End Card */}
                                      </div>
                                    );
                                  }
                                })()}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full max-w-full px-3 mt-0 lg:w-4/12 lg:flex-none">
                    <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                      {/* Heading */}
                      <div className="flex-auto p-4">
                        <Tabs>
                          <TabList
                            role="tablist"
                            className="tabs tabs-boxed bg-white justify-between px-8 md:px-4"
                          >
                            <Tab role="tab" className="tab">
                              <span className="text-sm flex flex-row items-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  data-slot="icon"
                                  class="w-5 h-5"
                                >
                                  <path d="M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM1.615 16.428a1.224 1.224 0 0 1-.569-1.175 6.002 6.002 0 0 1 11.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 0 1 7 18a9.953 9.953 0 0 1-5.385-1.572ZM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 0 0-1.588-3.755 4.502 4.502 0 0 1 5.874 2.636.818.818 0 0 1-.36.98A7.465 7.465 0 0 1 14.5 16Z" />
                                </svg>
                                Peer Reviews
                                {(() => {
                                  if (
                                    assignedSubmission?.filter(
                                      (item) => item.feedback === null
                                    ).length > 0
                                  ) {
                                    return (
                                      <span class="hidden inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-100 text-red-800 darki:bg-red-800/30 darki:text-red-500">
                                        +{" "}
                                        {
                                          assignedSubmission?.filter(
                                            (item) => item.feedback === null
                                          ).length
                                        }
                                      </span>
                                    );
                                  }
                                })()}
                              </span>
                            </Tab>
                            <Tab role="tab" className="tab">
                              <span className="text-sm flex flex-row items-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  data-slot="icon"
                                  class="w-5 h-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 2c-1.716 0-3.408.106-5.07.31C3.806 2.45 3 3.414 3 4.517V17.25a.75.75 0 0 0 1.075.676L10 15.082l5.925 2.844A.75.75 0 0 0 17 17.25V4.517c0-1.103-.806-2.068-1.93-2.207A41.403 41.403 0 0 0 10 2Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                My Reviews
                              </span>
                            </Tab>
                          </TabList>

                          <TabPanel>
                            {assignedSubmission
                              ?.filter((item) => item.pr_enabled === true)
                              ?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex my-4 flex-col bg-white border rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]"
                                  >
                                    <div className="p-4 md:p-7">
                                      <small> WEEK {item.week_id - 1} </small>
                                      <h3 className="text-lg font-bold text-gray-800 darki:text-white">
                                        {item.activity_name}
                                      </h3>

                                      <p className="mt-2 text-gray-500 dark:text-gray-400">
                                        {Object.entries(
                                          item?.parsed_content
                                        ).map(([key, value]) => (
                                          <p key={key}>
                                            <b>{key}: </b>{" "}
                                            {value?.length > 50
                                              ? value?.slice(0, 50) + "..."
                                              : value}
                                          </p>
                                        ))}
                                      </p>

                                      {item?.status === "Reviewing" ? (
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => {
                                            openModal(item);
                                            document
                                              .getElementById("my_modal_5")
                                              .showModal();
                                          }}
                                        >
                                          Add Review
                                        </button>
                                      ) : (
                                        <div className="flex items-center">
                                          <span className="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full darki:bg-teal-500/10 darki:text-teal-500">
                                            <svg
                                              className="flex-shrink-0 w-3 h-3"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
                                              <path d="m9 12 2 2 4-4" />
                                            </svg>
                                            Review Submitted {item?.status}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {assignedSubmission?.filter(
                              (item) => item.pr_enabled === true
                            )?.length === 0 && (
                                <div className="min-h-[15rem] flex flex-col bg-white  rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]">
                                  <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                                    <Waiting />

                                    <h3 className="text-lg font-bold text-gray-800 darki:text-white">
                                      Hang tight!
                                    </h3>
                                    <p className="mt-2 text-center text-gray-500 darki:text-gray-400">
                                      Your peers are working on their submissions.
                                      You'll receive reviews shortly.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </TabPanel>

                          <TabPanel>
                            <div className="">
                              {mySubmissions.length > 0 ? (
                                mySubmissions.map((item) => (
                                  <div key={item.id}>
                                    <div className="p-4 md:p-7">
                                      <small> WEEK {item.week_id - 1} </small>
                                      <h3 className="text-lg font-bold text-gray-800 darki:text-white">
                                        {item.activity_name}
                                      </h3>
                                      <p className="mt-2 text-gray-500 darki:text-gray-400">
                                        {item.feedback}
                                      </p>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          openMyModal(item);
                                          document
                                            .getElementById("my_modal_6")
                                            .showModal();
                                        }}
                                      >
                                        View Review
                                      </button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="min-h-[15rem] flex flex-col bg-white  rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]">
                                  <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                                    <WaitingSub />
                                    <h3 className="text-lg font-bold text-gray-800 darki:text-white">
                                      Hang tight!
                                    </h3>
                                    <p className="mt-2 text-center text-gray-500 darki:text-gray-400">
                                      Your peers are reviewing your activities
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>

                {/* end Navbar */}
                {/* cards */}
              </main>
            </>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <dialog id="my_modal_5" className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="modal-action absolute top-0 right-4  z-[99999]">
              <form method="dialog">
                <button className="btn btn-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </form>
            </div>

            <div className="w-full bg-white  transform   duration-200 easy-in-out rounded-t-2xl ">
              {/* <div className=" h-32  rounded-t-2xl">
                <img
                  className="w-full"
                  src="https://t3.ftcdn.net/jpg/06/04/80/16/360_F_604801664_HSbqxVq1qWoO5C8D1ls1PwcT9GTWgFhj.jpg"
                  alt=""
                />
              </div> */}
              {/* <div className="flex justify-center px-5 bg-white mt-12">
                <img
                  className="h-32 w-32 bg-white p-2 rounded-full   "
                  src={getSubById?.avatar}
                  alt=""
                />
              </div> */}
              <div className=" ">
                <div className=" px-14 bg-white">
                  <div style={{ paddingTop: "20px" }}>
                    {/* Other content components */}
                  </div>
                  <p className="text-gray-400  mt-2 hover:text-blue-500 text-center">
                    {getSubById?.email === "" ? "not found" : getSubById?.email}
                  </p>

                  <div class="flow-root mt-8">
                    <dl class="-my-3 divide-y divide-gray-100 text-sm">
                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Week </dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getSubById?.week_id - 1}
                        </dd>
                      </div>
                      {/* <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Content</dt>
                        <dd class="text-gray-700 sm:col-span-2">{processData(getSubById?.content)}</dd>
                      </div> */}

                      {Object.entries(getSubById?.parsed_content).map(
                        ([key, value]) => (
                          <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                            <dt class="font-medium text-gray-900">{key}</dt>
                            <dd class="text-gray-700 sm:col-span-2">{value}</dd>
                          </div>
                        )
                      )}

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Review</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          <textarea
                            onChange={(e) => setFeedback(e.target.value)}
                            className="input input-bordered h-28 w-full p-4"
                            placeholder="Share your thoughts.."
                          ></textarea>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <hr className="mt-6" />
                <div
                  className="btn btn-block btn-primary"
                  onClick={handleSubmitFeedback}
                >
                  Submit Review
                </div>
              </div>
            </div>

            {/* <div className="modal-action">
      <form method="dialog">
        <button className="btn">Close</button>
      </form>
    </div> */}
          </div>
        </dialog>
      )}

      {isMySubModalOpen && (
        <dialog id="my_modal_6" className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="modal-action absolute top-0 right-0  z-[99999]">
              <form method="dialog">
                <button className="btn btn-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </form>
            </div>

            <div className="w-full bg-white  transform   duration-200 easy-in-out rounded-t-2xl ">
              {/* <div className=" h-32  rounded-t-2xl">
                <img
                  className="w-full"
                  src="https://t3.ftcdn.net/jpg/06/04/80/16/360_F_604801664_HSbqxVq1qWoO5C8D1ls1PwcT9GTWgFhj.jpg"
                  alt=""
                />
              </div> */}
              {/* <div className="flex justify-center px-5 bg-white mt-12">
                <img
                  className="h-32 w-32 bg-white p-2 rounded-full   "
                  src={getSubById?.avatar}
                  alt=""
                />
              </div> */}
              <div className=" ">
                <div className=" px-14 bg-white">
                  <div style={{ paddingTop: "20px" }}>
                    {/* Other content components */}
                  </div>
                  <p className="text-gray-400  mt-2 hover:text-blue-500 text-center">
                    {getSubById?.email === "" ? "not found" : getSubById?.email}
                  </p>

                  <div class="flow-root mt-8">
                    <dl class="-my-3 divide-y divide-gray-100 text-sm">
                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Week </dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getMySubById?.week_id - 1}
                        </dd>
                      </div>
                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Feedback</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getMySubById?.feedback}
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Rate Feedback</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          <div className="whitespace-nowrap text-sm font-medium text-gray-800 darki:text-white">
                            <div className="rating">
                              <input
                                type="radio"
                                name="rating-2"
                                className="mask mask-star-2 bg-orange-400"
                                onChange={() => setRating(2)}
                              />
                              <input
                                type="radio"
                                name="rating-2"
                                className="mask mask-star-2 bg-orange-400"
                                checked
                                onChange={() => setRating(4)}
                              />
                              <input
                                type="radio"
                                name="rating-2"
                                className="mask mask-star-2 bg-orange-400"
                                onChange={() => setRating(6)}
                              />
                              <input
                                type="radio"
                                name="rating-2"
                                className="mask mask-star-2 bg-orange-400"
                                onChange={() => setRating(8)}
                              />
                              <input
                                type="radio"
                                name="rating-2"
                                className="mask mask-star-2 bg-orange-400"
                                onChange={() => setRating(10)}
                              />
                            </div>
                          </div>
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">
                          Is this an appropiate feedback?
                        </dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          <div className="whitespace-nowrap text-sm font-medium text-gray-800 darki:text-white">
                            <input
                              type="checkbox"
                              id="choose-me"
                              className="peer hidden"
                              onChange={handleCheckboxChange}
                              checked={isFlag}
                            />
                            <label
                              htmlFor="choose-me"
                              className="select-none cursor-pointer rounded-lg border-2 border-red-200
   py-3 px-6 font-bold text-gray-200 transition-colors duration-200 ease-in-out peer-checked:bg-red-600  peer-checked:border-red-200 "
                            >
                              {" "}
                              Report to admin!{" "}
                            </label>
                          </div>
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Sub Review</dt>
                        <dd class="text-gray-700">
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            className="input input-bordered p-4"
                            placeholder="Share your thoughts response"
                            onChange={(e) => setSubFeedback(e.target.value)}
                          ></textarea>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <hr className="mt-6" />
                <div
                  className="btn btn-primary btn-block"
                  onClick={handleSubmitSubFeedback}
                >
                  Submit
                </div>
              </div>
            </div>

            {/* <div className="modal-action">
      <form method="dialog">
        <button className="btn">Close</button>
      </form>
    </div> */}
          </div>
        </dialog>
      )}
    </div>
  );
};

export default Weeks;

const ListComponent = ({ items, className = '' }) => {
    return (
        <ul className={`marker:text-blue-600 list-disc ps-5 space-y-2 text-sm text-gray-600 dark:text-neutral-400 ${className}`}>
            {items.map((item, index) => (
                <li key={index}>
                    <h3 className="font-semibold text-sm text-gray-600">{item.title}</h3>
                    <p>{item.description}</p>
                </li>
            ))}
        </ul>
    );
};

export default ListComponent;

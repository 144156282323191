// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek3 = ({ onImageIndexChange }) => {
  // const slideImages = [
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700069414/AHbranding/Slide_16_9_-_8_vjyhxy.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700070293/AHbranding/Slide_16_9_-_9_hcpcxq.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700070293/AHbranding/Slide_16_9_-_10_popdfd.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700070291/AHbranding/Slide_16_9_-_11_tkedhc.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700070292/AHbranding/Slide_16_9_-_12_xfbyei.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700070291/AHbranding/Slide_16_9_-_13_dar2ju.png'



  // ];

  // const handleImageIndexChange = (index) => {
  //   if (onImageIndexChange) {
  //     onImageIndexChange(index);
  //   }
  // };

  const storedImages = localStorage.getItem('week3Images');
  const slideImages = storedImages ? JSON.parse(storedImages) : [];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };
  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
      {/* Other content for your gallery page */}
    </div>
  );
};

export default SliderWeek3;

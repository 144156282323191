// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek6_2 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_109_desmx3.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_110_cslmcx.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_111_zvdmks.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_112_bvssqj.png',
    'https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956468/Slide_16_9_-_113_pt73fn.png',
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek6_2;

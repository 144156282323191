import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import { tr } from "date-fns/locale";
import QuizComponent from "./QuizComponent";

const Week6 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 7;
  const week_name = "week_6";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config
      )
      .then((response) => {
        console.log(response);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
          config
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 9, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_7",
              },
              config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(
          `${process.env.REACT_APP_API_KEY}/api/peer_feedback/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: 4,
            content: swotSubmission,
            pathway: 1,
            feedback: swotFeedback,
            rating: 0,
          },
          config
        );
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        return;
      } else {
        axios.post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission,
            pathway: 1,
            status: "Reviewing",
            rating: 0,
            pr_enabled: false,
            activity_name: "Howard Schultz and Starbucks",
          },
          config
        );
        setDisableBtn(true);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      }
    }
  };

  const totalSteps = 10; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const questionsData = [
    {
      question:
        '"Cutting corners" in work can be justified if it leads to meeting important deadlines and impressing superiors.',
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      explanation:
        "Cutting corners in work is never justified, even if it seems to lead to short-term benefits like meeting deadlines or impressing superiors. It can compromise quality, ethics, and long-term success.",
    },
    {
      question:
        "Setting clear ethical boundaries and standing by them, even when it is inconvenient or leads to a short-term disadvantage, strengthens leadership credibility.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
      explanation:
        "Setting clear ethical boundaries and sticking to them, even in challenging situations, is crucial for building trust and credibility as a leader.",
    },
    {
      question:
        "Leadership responsibility only extends to achieving goals, not to the means by which they are achieved.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      explanation:
        "Leadership responsibility encompasses both achieving goals and the methods used to achieve them. Ethical leadership involves considering the means as well as the ends.",
    },
    {
      question:
        "Recognizing the impact of your decisions on all stakeholders is an unnecessary complication in the decision-making process.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      explanation:
        "Recognizing and considering the impact of decisions on all stakeholders is essential for responsible decision-making and long-term success.",
    },
    {
      question:
        "Consistently acting with integrity builds a reputation that can enhance one's influence more than any single decision or action.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
      explanation:
        "Acting with integrity builds trust and credibility over time, which enhances one's influence and leadership effectiveness more than any isolated decision or action.",
    },
  ];

  const accordionData = [
    {
      title: "Demonstration",
      content:
        "Ayesha demonstrates self-awareness in her leadership role by consistently reflecting on her actions, decisions, and their impact on others. She is open to feedback and actively seeks to understand how her emotions influence her leadership style and decision-making process.",
    },
    {
      title: "Lesson",
      content:
        "A key lesson we can draw from Ayesha's journey is the importance of self-awareness in leadership. Like Ayesha, we should regularly reflect on our experiences, seek feedback, and understand how our emotions shape our leadership approach. This self-awareness allows us to make informed decisions, adapt to challenges, and foster a positive and collaborative leadership environment.",
    },
  ];

  const accordionData2 = [
    {
      title: "Focus on the Principles",
      content:
        "Consider the broader impact of your actions by framing your decisions around ethical principles rather than short-term gains. Research indicates that leaders who prioritize ethical standards over immediate results cultivate a more loyal, trusting, and high-performing team.",
    },
    {
      title: "Distinguishing Ethics from Morals",
      content:
        "Ground your leadership approach in a clear understanding of both ethics and morals. While morals often stem from individual beliefs and cultural backgrounds, ethics typically refer to the accepted standards of behavior in professional and public domains. Appreciating this distinction can enhance your ability to navigate complex situations with clarity and confidence, creating an environment where ethical considerations guide the way to sustainable success.",
    },
  ];

  const accordionData3 = [
    {
      title: "Vision for the Future",
      content:
        "A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.",
    },
    {
      title: "Ethical Foundations",
      content:
        "Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.",
    },
    {
      title: "Environmental Stewardship",
      content:
        "Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization's identity and mission.",
    },
    {
      title: "Community Enrichment",
      content:
        "Sustainable leadership involves a commitment to the well-being of the community. Leaders invest in social initiatives, support educational programs, and engage in partnerships that address social issues. Their legacy is marked by the positive impact on the community’s health, wealth, and social fabric.",
    },
  ];

  const accordionData4 = [
    {
      title: "Unhealthy Decision-Making Processes",
      sub: "Habits to challenge",
      content:
        "Short-Term Wins Over Long-Term Values:\n" +
        "• Deciding based on immediate benefits can undermine ethical standards and long-term trust.\n\n" +
        "Ignoring Diverse Perspectives:\n" +
        "• Failing to seek out or consider diverse viewpoints can lead to unethical or biased decisions.\n\n" +
        "Overconfidence:\n" +
        "• Believing too strongly in our own ethical compass can lead to blind spots in decision-making.\n\n" +
        "Justifying Ends with Means:\n" +
        "• Rationalizing unethical actions for a desired outcome compromises integrity.\n\n",
    },
    {
      title: "Unhealthy Reflective Practices",
      sub: "Habits to challenge",
      content:
        "Defensive Reasoning:\n" +
        "• Defending your decisions without considering constructive criticism can prevent ethical growth.\n\n" +
        "Blaming Culture:\n" +
        "• Assigning blame to the organizational culture or others avoids personal ethical responsibility.\n\n" +
        "Not Learning from Ethical Failures:\n" +
        "• Missing the opportunity to learn from mistakes limits ethical development.\n\n" +
        "Avoiding Ethical Conversations:\n" +
        "• Not engaging in discussions about ethics can keep you from understanding different perspectives.\n\n",
    },
    {
      title: "Unhealthy Execution of Decisions",
      sub: "Habits to challenge",
      content:
        "Disregarding Impact:\n" +
        "• Making decisions without considering their impact on all stakeholders can lead to unethical outcomes.\n\n" +
        "Avoidance of Accountability:\n" +
        "• Failing to own up to the consequences of your decisions can erode ethical standards.\n\n" +
        "Conformity Pressure:\n" +
        "• Succumbing to peer pressure or organizational norms against your ethical judgment.\n\n" +
        "Compartmentalization:\n" +
        "• Separating personal values from professional actions can lead to ethical dissonance.\n\n",
    },
    {
      title: "Habits to Cultivate for Ethical Decision Making",
      content:
        "Regular Ethical Check-ins:\n" +
        "• Taking time to reflect on the ethical dimensions of your decisions.\n\n" +
        "Seeking Diverse Input:\n" +
        "• Actively inviting different perspectives before making a decision.\n\n" +
        "Long-Term Thinking:\n" +
        "• Considering the extended consequences of decisions on all stakeholders.\n\n" +
        "Transparency:\n" +
        "• Being open about decision-making processes to encourage trust and accountability.\n\n",
    },
  ];

  const accordionData5 = [
    {
      title: "Self-Reflection and Honest Assessment",
      content:
        "• Reflecting on your actions and decisions is key to understanding your leadership impact. Consider: What decisions have I made recently?\n" +
        "• Were these decisions explained clearly to those affected?\n" +
        "• How am I holding myself responsible for the outcomes of these decisions?\n\n",
    },
    {
      title: "Recognize Patterns and Outcomes",
      content:
        "• Which actions have led to positive outcomes?\n" +
        "• Where have I fallen short in being open and responsible, and how can I improve?\n" +
        "• How have my actions aligned with my commitment to being transparent and accountable?\n\n",
    },
    {
      title: "Seek Diverse Insights",
      content:
        "• What alternative viewpoints have I considered?\n" +
        "• In what ways have I engaged others to hold me accountable?\n" +
        "• How might I be perceived by my peers, and what can I learn from their feedback?\n\n",
    },
  ];

  const accordionData6 = [
    {
      title: "Explore the Pillars of Sustainable Leadership",
      content:
        "A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n" +
        "Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n" +
        "Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization's identity and mission.\n\n",
    },
  ];

  const accordionData7 = [
    {
      title: "Effective Communication",
      content:
        "Drivers of Effective Communication\n" +
        "We explored the elements of effective communication, including the importance of giving and receiving meaningful messages, the power of non-verbal communication, and the need for adaptable communication styles. Recognizing and understanding these elements are vital for building strong communication skills.\n\n" +
        "Importance of Meaning in Communication\n" +
        "We dove into why it's crucial to infuse our communications with meaning and purpose. We explored this through a narrative that showed how Preetam learned to craft meaningful messages that resonated with his audience and made him a more effective leader.\n\n" +
        "Virtual Public Speaking Exercise\n" +
        "You prepared speeches on provided topics, recorded them, and submitted your videos for peer review. This exercise not only gave you a platform to practice your public speaking skills but also provided you with constructive feedback from your peers.\n\n" +
        "Peer Review\n" +
        "You provided valuable feedback to your peers on their speeches and discussion contributions. Your thoughtful insights and constructive criticism contributed to a supportive learning environment and helped your peers in their journey of improving their communication skills.\n\n",
    },
    {
      title: "Shahzeb's Leadership Narrative",
      content:
        "Through Shahzeb's experiences, we witnessed the practical application of empathetic leadership, understanding its significance in fostering trust, inclusivity, and collaboration.\n\n",
    },
    {
      title: "Empathy Mapping Activity",
      content:
        "By stepping into Ahmed's world, we practiced the art of empathy, gaining insights into the myriad emotions, thoughts, and perceptions one might experience in a new environment.\n\n",
    },
    {
      title: "Role-Play Activity",
      content:
        'The "Group Project Challenge" allowed us to immerse ourselves in real-world group dynamics, emphasizing the importance of empathy in effective communication, conflict resolution, and decision-making.\n\n',
    },
    {
      title: "Peer Reviews",
      content:
        "Your thoughtful feedback and reflections showcased a deep understanding of empathy's role in leadership, fostering a collaborative and supportive learning environment.\n\n",
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  const [disableBtn, setDisableBtn] = useState(false);

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false);

  let allFieldsHaveMinimumWords = false;

  useEffect(() => {
    // const wordCounts = Object.values(swotSubmission).map(value => value.split(/\s+/).filter(word => word !== '').length);
    const wordCounts = Object.values(swotSubmission).map(
      (value) => value.split(/\s+/).filter((word) => word !== "").length
    );

    // Check if all fields have at least 50 words each
    const allFieldsHaveMinimumWords = wordCounts.every((count) => count >= 50);

    // alert("WORDS: "+allFieldsHaveMinimumWords);
    if (
      currentStep === 0 ||
      currentStep === 2 ||
      currentStep === 4 ||
      currentStep === 5 ||
      currentStep === 6 ||
      currentStep === 7
    ) {
      setNextBtnCondtion(true); // Enable button at step 0
    } else if (currentStep === 1 && currentImageIndex < 8) {
      setNextBtnCondtion(false);
    } else if (currentStep === 1 && currentImageIndex > 7) {
      setNextBtnCondtion(true);
    } else if (currentStep === 3 && currentImageIndex < 4) {
      setNextBtnCondtion(false);
    } else if (currentStep === 3 && currentImageIndex > 3) {
      setNextBtnCondtion(true);
    } else if (currentStep === 8 && currentImageIndex < 3) {
      setNextBtnCondtion(false);
    } else if (currentStep === 8 && currentImageIndex > 2) {
      setNextBtnCondtion(true);
    } else if (currentStep === 9 && status === "Completed") {
      setNextBtnCondtion(true);
    } else if (
      currentStep === 9 &&
      status === "Active" &&
      allFieldsHaveMinimumWords &&
      disableBtn
    ) {
      setNextBtnCondtion(true);
    } else {
      setNextBtnCondtion(false);
    }
  }, [currentStep, currentImageIndex, swotSubmission, status, disableBtn]);

  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem("week6Images");
    const slideImages = [
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736941672/Slide_16_9_-_106_zzjmoa.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736941672/Slide_16_9_-_107_su7nay.png",
      // Add other image URLs as needed
    ];
  }, []);

  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem("week6_2Images");
    const slideImages = [
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_109_desmx3.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_110_cslmcx.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_111_zvdmks.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956469/Slide_16_9_-_112_bvssqj.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736956468/Slide_16_9_-_113_pt73fn.png",
      // Add other image URLs as needed
    ];
  }, []);

  const questionsData1 = [
    {
      question:
        "Scenario 1: The LUMS Entrepreneurial Society runs into budget issues mid-semester, leaving them short on funds for their planned startup showcase.",
      options: ["Internal", "External"],
      correctAnswer: "Internal",
      explanation:
        "Correct! This is an internal risk as it involves the society’s financial management.",
    },

    {
      question:
        "Scenario 2: A prominent guest cancels just hours before the LUMS Law and Politics Society’s planned talk on judicial reforms, leaving the team scrambling for a replacement.",
      options: ["Internal", "External"],
      correctAnswer: "External",
      explanation:
        "Correct! This is an external risk, as it relates to a guest speaker’s availability.",
    },

    {
      question:
        "Scenario 3: Dramaline newly elected president struggles with a lack of guidance, leading to misunderstandings about event policies and member roles.",
      options: ["Internal", "External"],
      correctAnswer: "Internal",
      explanation:
        "Correct! This is an internal risk as it involves internal leadership transition issues.",
    },

    {
      question:
        "Scenario 4: New university rules restrict outdoor gatherings due to the smog, impacting Hum Aahang’s plans for a major on-campus Diwali event.",
      options: ["Internal", "External"],
      correctAnswer: "External",
      explanation:
        "Correct! This is an external risk as it’s caused by university policy changes.",
    },

    {
      question:
        "Scenario 5: During the DanceLUMS’s Take the Stage event, a participant gets injured, requiring the organizers to ensure prompt medical assistance.",
      options: ["Internal", "External"],
      correctAnswer: "External",
      explanation:
        "Correct! This is an external risk as it involves health and safety concerns for participants.",
    },
  ];

  const questionsData2 = [
    {
      question:
        "Your society plans an outdoor event, but there is a 70% chance of rain. What strategy would you use?",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Mitigate",
      explanation:
        "Correct! You can move the event indoors or arrange for rain covers to reduce the impact of rain.",
    },

    {
      question: "A guest speaker for your seminar cancels at the last minute.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Mitigate",
      explanation:
        "Correct! You can arrange a backup speaker or adjust the agenda to fill the gap.",
    },

    {
      question:
        "Your society treasurer loses a small amount of cash while preparing for an event.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Accept",
      explanation:
        "Correct! The loss is minor and does not significantly impact the event’s success, so accepting it is reasonable.",
    },

    {
      question:
        "You discover that the venue for an upcoming event is unsafe due to structural issues.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Avoid",
      explanation:
        "Correct! Eliminating the risk entirely by cancelling or changing the venue is the safest approach.",
    },

    {
      question:
        "Your team expects a delay in receiving logistical supplies for an event, but the event can still proceed.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Mitigate",
      explanation:
        "Correct! Planning alternative suppliers or adjusting the schedule can manage the delay effectively.",
    },

    {
      question:
        "Your society signs an MOU with an external vendor to cover your social set up. There is a chance of financial loss if the vendor fails to deliver.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Transfer",
      explanation:
        "Correct! Using a specific MOU clause shifts the financial risk to the vendor or insurer.",
    },

    {
      question:
        "The society is hosting a high-profile event with VIP guests who are prominent political personalities. Any potential security issues could harm your reputation.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Mitigate",
      explanation:
        "Correct! Hiring additional security personnel reduces the likelihood of incidents.",
    },

    {
      question:
        "The society needs to secure funding for an event, but sponsorship offers are limited.",
      options: ["Avoid", "Mitigate", "Transfer", "Accept"],
      correctAnswer: "Mitigate",
      explanation:
        "Correct! Approaching more sponsors or adjusting the event scale is a proactive way to manage funding risks.",
    },
  ];

  const questionsData3 = [
    {
      question: "Choose the most effective action plan:",
      options: [
        " Cancel the event.",
        "Find a backup speaker",
        "Continue without a speaker",
      ],
      correctAnswer: "Find a backup speaker",
      explanation:
        "Correct! This option balances continuity with transparency. Finding a backup speaker maintains the event’s purpose, while updating attendees ensures effective communication.",
    },
  ];

  const steps = [
    // Define the content and structure of each step

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 1:</b> Identifying Internal v/s External Risks
      </h2>

      <div className="mt-6 flex justify-start w-full">
        <SliderWeek6
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1:</b> Risk Identification Quiz
      </h2>

      <p class="mb-4">
        <strong>Instructions:</strong> Read each scenario carefully and select
        whether it represents an Internal or External risk. You’ll receive
        instant feedback after each question!
      </p>

      <QuizComponent questionsData={questionsData1} />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 2: </b> Creating a Risk Matrix
      </h2>

      <div class="flex justify-center mb-6">
        <img
          src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1736954800/Slide_16_9_-_108_thmjjj.png"
          alt="Risk Matrix"
          class="rounded-md shadow-md"
          style={{ maxHeight: "800px", maxWidth: "1000px" }}
        />
      </div>

      <h3 class="text-lg font-bold mb-2">
        Title: Contextual Examples for Student Societies
      </h3>
      <ol class="list-decimal list-inside">
        <li class="mb-2">
          <strong>Low Risk:</strong> Event attendance slightly below
          expectations.
        </li>
        <li class="mb-2">
          <strong>Moderate Risk:</strong> Miscommunication with external vendors
          causing delays.
        </li>
        <li class="mb-2">
          <strong>High Risk:</strong> Significant budget overruns impacting
          other events.
        </li>
        <li>
          <strong>Critical Risk:</strong> A major incident (e.g., safety issues)
          at an event harming the society's reputation.
        </li>
      </ol>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 3: </b> Risk Mitigation Strategies
      </h2>

      <div className="mt-6 flex justify-start w-full">
        <SliderWeek6_2
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 3: </b> Risk Mitigation Quiz
      </h2>

      <h2 class="text-lg font-bold mb-2">Instructions:</h2>
      <p>
        Read each scenario carefully and determine the nature of the risk based
        on the Risk Matrix you studied earlier. As per the nature and level of
        risk, choose which one of the Risk Mitigation Strategies you would
        employ to deal with the scenario. You’ll receive instant feedback after
        each question!
      </p>

      <QuizComponent questionsData={questionsData2} />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 4: </b> Crisis Response Simulation
      </h2>

      <p class="mb-6">
        <strong>Scenario: </strong>Your society is hosting an event, and the
        guest speaker cancels one hour before their slot. Your audience is
        already gathering.
      </p>

      <p class="mb-2">
        <strong>Task: </strong>Your role is to manage the crisis effectively.
        Consider the following:
      </p>
      <ol class="list-decimal list-inside mb-6">
        <li>Identify your immediate priorities.</li>
        <li>Select the best 3-step action plan from the options below.</li>
      </ol>

      <div class="mb-4">
        <h4 class="font-bold mb-2 text-base">
          A. Cancel the event and inform attendees.
        </h4>
        <ul class="list-disc list-inside mb-4">
          <li>
            <strong>Step 1:</strong> Announce the cancellation immediately.
          </li>
          <li>
            <strong>Step 2:</strong> Apologize to attendees and explain the
            situation.
          </li>
          <li>
            <strong>Step 3:</strong> Offer refunds or complimentary access to a
            future event.
          </li>
        </ul>
      </div>
      <div class="mb-4">
        <h4 class="font-bold mb-2 text-base">
          B. Find a backup speaker, inform the audience, and adjust the
          schedule.
        </h4>
        <ul class="list-disc list-inside mb-4">
          <li>
            <strong>Step 1:</strong> Quickly identify a backup speaker or
            alternative presentation.
          </li>
          <li>
            <strong>Step 2:</strong> Inform the audience about the change and
            provide updates.
          </li>
          <li>
            <strong>Step 3:</strong> Adjust the schedule to accommodate the
            changes.
          </li>
        </ul>
      </div>
      <div>
        <h4 class="font-bold mb-2 text-base">
          C. Continue without a speaker and extend other activities.
        </h4>
        <ul class="list-disc list-inside">
          <li>
            <strong>Step 1:</strong> Announce the absence of the speaker to
            attendees.
          </li>
          <li>
            <strong>Step 2:</strong> Extend networking sessions or other
            interactive segments.
          </li>
          <li>
            <strong>Step 3:</strong> End the event earlier than planned.
          </li>
        </ul>
      </div>

      <QuizComponent questionsData={questionsData3} />
    </section>,

    // <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     Welcome to <b>Week 6: Ethical Leadership</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">
    //     Welcome to Week 6 of your leadership development journey! This week, we
    //     immerse ourselves in the critical theme of "Ethical Leadership and
    //     Decision-Making." Explore the intricate world of ethical dilemmas, where
    //     every choice has profound implications for individuals and
    //     organizations. Learn about the principles that guide ethical leaders,
    //     the challenging decisions they face, and how these choices shape their
    //     leadership legacy. Engage in dynamic activities, reflective exercises,
    //     and insightful discussions to deepen your understanding of ethical
    //     leadership. This week is about honing your ability to navigate complex
    //     ethical landscapes with integrity, courage, and wisdom.
    //   </p>
    //   <div className="mt-6 flex justify-center w-full h-full">
    //     <Quote
    //       content={
    //         "Ethics is knowing the difference between what you have a right to do and what is right to do."
    //       }
    //       person={"Potter Stewart"}
    //     />
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek6
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />
    //   </div>
    // </section>,

    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   {/* Step 1 content */}

    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8  lg:gap-16">
    //       {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //   <img
    //     className="w-full"
    //     src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
    //   />
    // </div> */}

    //       <div className="">
    //         <div>
    //           <h2 className="text-xl font-medium sm:text-xl">
    //             <b> Ethical Leadership Core Concepts </b>
    //           </h2>
    //           <p className="mt-4 text-gray-600">
    //             Do you ever find yourself at the crossroads of decision-making,
    //             wondering not just about the most efficient outcome but about
    //             the right thing to do? Or find that in the rush of meeting
    //             targets, the essential questions of fairness, integrity, and
    //             responsibility sometimes take a back seat? If so, you're
    //             engaging with the very questions at the heart of ethical
    //             leadership. Ethical leadership isn't about choosing the easy
    //             path but about finding the right one. And the best ethical
    //             leaders aren't those who only follow a moral code but those who
    //             integrate these principles into every aspect of their
    //             leadership. Let's look at how to deepen our understanding and
    //             practice of ethical leadership.
    //           </p>

    //           <div className="mt-6">
    //             <div className="container flex flex-col justify-center mx-auto px-0 ">
    //               <div className="">
    //                 <Collapse accordionData={accordionData2} />
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //         <div className="divider"></div>
    //         <div></div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek6_2
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b></b>
    //   </h2>
    //   <p className="mt-4 text-gray-600"></p>

    //   <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
    //     {currentQuestion < questionsData.length ? (
    //       <div>
    //         <h2 className="text-xl text-center font-semibold mb-8">
    //           {questionsData[currentQuestion].question}
    //         </h2>
    //         <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
    //           {questionsData[currentQuestion].options.map((option, index) => (
    //             <div
    //               key={index}
    //               className={`${
    //                 showExplanation &&
    //                 option === questionsData[currentQuestion].correctAnswer
    //                   ? "bg-green-500 text-white"
    //                   : selectedOption === option
    //                   ? "bg-purple-200 "
    //                   : "bg-white"
    //               } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
    //               onClick={() => handleOptionSelect(option)}
    //             >
    //               {option}
    //             </div>
    //           ))}
    //         </div>
    //         {selectedOption && (
    //           <div className="flex justify-center">
    //             <button
    //               className="btn btn-outline-primary mt-4"
    //               onClick={handleNextQuestion}
    //             >
    //               Show Answer
    //             </button>
    //           </div>
    //         )}
    //         {showExplanation && (
    //           <div className="mt-4">
    //             <p className="font-semibold">Correct Answer:</p>
    //             <p className="text-green-500">
    //               {questionsData[currentQuestion].correctAnswer}
    //             </p>
    //             {/*<p className="font-semibold mt-2">Explanation:</p>*/}
    //             <p>{questionsData[currentQuestion].explanation}</p>
    //             <div className="flex justify-center mt-4">
    //               <button
    //                 className="btn btn-outline-primary"
    //                 onClick={handleNextQuestionOrFinish}
    //               >
    //                 {currentQuestion + 1 < questionsData.length ? "" : "Finish"}

    //                 {currentQuestion + 1 < questionsData.length
    //                   ? "Next Question"
    //                   : ""}
    //               </button>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     ) : (
    //       <div>
    //         <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
    //       </div>
    //     )}
    //   </div>
    // </section>,

    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   {/* Step 1 content */}

    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8  lg:gap-16">
    //       {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //   <img
    //     className="w-full"
    //     src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
    //   />
    // </div> */}

    //       <div className="">
    //         <div>
    //           <h2 className="text-xl font-medium sm:text-xl">
    //             <b> Decision-Making Habits in Ethical Leadership </b>
    //           </h2>
    //           <p className="mt-4 text-gray-600">
    //             Good leaders make choices that show their integrity and care for
    //             others, not just once in a while, but all the time. This means
    //             you need to look carefully at the habits that shape your
    //             decisions. Are you making choices because they're fast and easy,
    //             or because they're right? Do you think about different points of
    //             view before you decide, or do you just go with what you already
    //             believe? Can you stick with your choice even when it's not the
    //             easy one? By asking ourselves these questions, we can figure out
    //             which of our habits might be getting in the way of being the
    //             best leaders we can be. Then, we can start to change those
    //             habits and build new ones that make sure our leadership is not
    //             just about getting things done, but about doing things the right
    //             way.
    //           </p>

    //           <div className="mt-6">
    //             <div className="container flex flex-col justify-center mx-auto px-0 ">
    //               <div className="">
    //                 <Collapse accordionData={accordionData4} />
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //         <div className="divider"></div>
    //         <div></div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   {/* Step 1 content */}

    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8  lg:gap-16">
    //       <div className="">
    //         <div>
    //           <h2 className="text-xl font-medium sm:text-xl mb-8">
    //             <b> Transparency and Accountability in Leadership </b>
    //           </h2>

    //           <strong>
    //             Maintaining Transparency and Accountability: Your Leadership
    //             Check-Up
    //           </strong>
    //           <p className="mt-4 text-gray-600">
    //             Just as you need regular health check-ups to ensure your body is
    //             functioning optimally, the same concept applies to your
    //             leadership habits. It’s essential to routinely evaluate and
    //             fine-tune your approach to transparency and accountability.
    //             Reflecting on these aspects is like a tune-up for your ethical
    //             leadership engine.
    //           </p>

    //           <div className="mt-6">
    //             <div className="container flex flex-col justify-center mx-auto px-0 ">
    //               <div className="">
    //                 <Collapse accordionData={accordionData5} />
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //         <div className="divider"></div>
    //         <div></div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   {/* Step 1 content */}

    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8  lg:gap-16">
    //       {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //   <img
    //     className="w-full"
    //     src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
    //   />
    // </div> */}

    //       <div className="">
    //         <div>
    //           <h2 className="text-xl font-medium sm:text-xl">
    //             <b> Building Sustainable Leadership </b>
    //           </h2>
    //           <p className="mt-4 text-gray-600">
    //             Sustainable leadership is about more than immediate
    //             achievements; it's about fostering an environment where success
    //             endures. Leaders with this focus make decisions mindful of both
    //             today’s demands and tomorrow’s possibilities. They prioritize
    //             ethical behavior, environmental care, and community well-being,
    //             ensuring that their strategies benefit all, not just the bottom
    //             line. These leaders are legacy builders, setting up systems and
    //             cultures that thrive beyond their presence. They balance the
    //             needs of the present with the foresight to maintain
    //             opportunities for the future, ensuring the continuous prosperity
    //             of the organizations and communities they serve. In essence,
    //             sustainable leadership is about creating a narrative of ongoing
    //             progress, planting seeds for the future while nurturing the
    //             growth of today.
    //           </p>

    //           <div className="mt-6">
    //             <span className="font-bold">
    //               Explore the Pillars of Sustainable Leadership:
    //             </span>
    //             <div className="container flex flex-col justify-center mx-auto mt-4 px-0 ">
    //               <div className="">
    //                 <Collapse accordionData={accordionData3} />
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //         <div className="divider"></div>
    //         <div></div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    // // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    // // {/* Step 1 content */}

    // // <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    // //   <div className="grid grid-cols-1 gap-8  lg:gap-16">
    // //     {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    // //       <img
    // //         className="w-full"
    // //         src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
    // //       />
    // //     </div> */}

    // //     <div className="">
    // //       <div>
    // //         <h2 className="text-xl font-medium sm:text-xl">
    // //           <b> Building Sustainable Leadership </b>
    // //         </h2>
    // //         <p className="mt-4 text-gray-600">
    // //         Sustainable leadership is about more than immediate achievements; it's about fostering an environment where
    // // success endures. Leaders with this focus make decisions mindful of both today’s demands and tomorrow’s
    // // possibilities. They prioritize ethical behavior, environmental care, and community well-being, ensuring that
    // // their strategies benefit all, not just the bottom line.
    // // These leaders are legacy builders, setting up systems and cultures that thrive beyond their presence. They
    // // balance the needs of the present with the foresight to maintain opportunities for the future, ensuring the
    // // continuous prosperity of the organizations and communities they serve.
    // // In essence, sustainable leadership is about creating a narrative of ongoing progress, planting seeds for the
    // // future while nurturing the growth of today

    // //         </p>

    // //         <div className="mt-6">
    // //           <div className="container flex flex-col justify-center mx-auto px-0 ">
    // //             <div className="">

    // //             <Collapse accordionData={accordionData6}/>

    // //             </div>
    // //           </div>
    // //         </div>
    // //       </div>

    // //       <div className="divider"></div>
    // //       <div>

    // //       </div>
    // //     </div>
    // //   </div>
    // // </div>
    // // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek6_3
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />
    //   </div>
    // </section>,

    // // <section
    // //       className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    // //       style={{ height: "100%" }}
    // //     >
    // //       <div className="mt-6 flex justify-start w-full">
    // //         <SliderWeek6_4 className='w-full' onImageIndexChange={handleImageIndexChange} />

    // //       </div>
    // //     </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Practice Activity – Case Study</b>
    //   </h2>

    //   <ul
    //     className="mt-4 bg-amber-50 p-2 mb-7 rounded-xl border-2 border-amber-100 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    //         />
    //       </svg>
    //       Instructions{" "}
    //     </p>

    //     <li className="m-6">
    //       Read the Case Study: Thoroughly read the narrative about Howard
    //       Schultz and Starbucks during the COVID-19
    //     </li>
    //     <li className="m-6">
    //       Develop Your Response: Discuss what you believe should be the key
    //       ethical considerations in your decisionmaking process.
    //     </li>
    //     <li className="m-6">
    //       Your response should be brief and to the point, ideally 3-4 lines.
    //     </li>
    //     <li className="m-6">
    //       Highlight specific aspects of the case study that influenced your
    //       understanding.
    //     </li>
    //     <li className="m-6">
    //       Critically analyze both the positive and negative implications of your
    //       decisions.
    //     </li>
    //   </ul>

    //   <p className="mt-4 text-gray-600">
    //     In the bustling world of global business, where every decision can
    //     ripple through markets and communities, the story of Howard Schultz, the
    //     CEO of Starbucks, stands as a testament to the power and complexity of
    //     ethical leadership. Starbucks, under Schultz's visionary guidance, had
    //     grown from a simple coffee retailer into a symbol of corporate
    //     responsibility and employee care. Schultz, a proponent of "conscious
    //     capitalism," believed that businesses should operate with a keen sense
    //     of ethics, considering the well-being of all stakeholders: employees,
    //     customers, and the broader community. The year 2020 unfurled a challenge
    //     like no other: the COVID-19 pandemic. It swept across the globe, leaving
    //     businesses grappling with unforeseen dilemmas. For Starbucks, a brand
    //     synonymous with community and connection, the pandemic posed a critical
    //     question: Should they keep their stores open to sustain revenue and
    //     employment or close them to protect their staff and patrons from the
    //     virus? This decision was particularly agonizing given the varying
    //     degrees of the pandemic's impact across different regions and the
    //     economic implications of store closures.
    //     <br />
    //     <br />
    //     Amidst this turmoil, Schultz stood firm. He chose to temporarily shutter
    //     most of Starbucks' stores in heavily affected areas, significantly
    //     reducing operating hours and shifting to a "to-go" model in others. This
    //     bold move, made despite the potential financial losses and shareholder
    //     trepidation, was a daring dance on the tightrope of ethical
    //     decision-making. Schultz's choice, grounded in the health and safety of
    //     his employees and customers, was a reflection of Starbucks'
    //     long-standing commitment to its people. The implications of this
    //     decision were manifold. Firstly, it underlined Starbucks' unwavering
    //     dedication to employee welfare. By prioritizing health over immediate
    //     financial outcomes, Schultz's decision served as a beacon of the
    //     company's ethical stance, reinforcing the bond of trust and respect with
    //     its employees. Secondly, it resonated deeply with customers, bolstering
    //     their trust in the brand. In a time of widespread uncertainty and fear,
    //     Starbucks' commitment to health and safety shone as an example of
    //     corporate responsibility. However, the decision was not without its
    //     challenges. Financially, Starbucks faced a turbulent period. Sales
    //     plummeted, revenues dipped, and the future looked uncertain.
    //     Shareholders expressed their concerns, uneasy about the impact of the
    //     store closures on the company's profitability. Yet, Schultz navigated
    //     these choppy waters with the steadiness of a seasoned captain. He
    //     communicated transparently with shareholders, explaining the ethical
    //     rationale behind the decision and reinforcing the importance of
    //     long-term sustainability over short-term gains.
    //     <br />
    //     <br />
    //     The long-term impact of Schultz's decision was profound. Starbucks'
    //     reputation as a socially responsible brand was greatly enhanced. The
    //     company was lauded for its proactive stance during the pandemic, setting
    //     a benchmark for corporate response in times of crisis. Employee loyalty
    //     and satisfaction surged, as the workforce felt valued and protected.
    //     Customers, too, rallied around the brand, their loyalty deepening due to
    //     Starbucks' ethical actions. This heightened sense of brand loyalty was
    //     not just a testament to the company's ethical choices but also a beacon
    //     of hope in a time of global despair. Financially, while Starbucks
    //     weathered an initial storm, the company rebounded with remarkable
    //     resilience. The decision to temporarily close stores, initially seen as
    //     a financial gamble, played a crucial role in the company’s rapid
    //     recovery post-pandemic. This turnaround was a powerful demonstration of
    //     the economic viability of ethical decision-making. It showed that
    //     ethical choices, though fraught with immediate challenges, could pave
    //     the way for sustainable success.
    //     <br />
    //     <br />
    //     In conclusion, Howard Schultz's ethical decision-making during the
    //     COVID-19 pandemic serves as a compelling narrative in the annals of
    //     corporate leadership. It illustrates how challenging times call for
    //     leaders who can balance personal beliefs with professional
    //     responsibilities, making decisions that shape not only their path but
    //     also the culture and values of their organizations. Schultz’s story is a
    //     vivid reminder that ethical leadership, while demanding, is integral to
    //     navigating crises, building lasting relationships, and ensuring
    //     sustainable success.
    //   </p>

    //   {status === "Completed" || disableBtn || allFieldsHaveMinimumWords ? (
    //     <div className="my-4">
    //       <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">
    //         <ul>
    //           <li>
    //             <b>Principles:</b>
    //             <br /> {swotSubmission?.principles}
    //             <br />
    //             <br />
    //           </li>
    //           <li>
    //             <b>Choices:</b>
    //             <br /> {swotSubmission?.choices}
    //             <br />
    //             <br />
    //           </li>
    //           <li>
    //             <b>Stakeholders:</b>
    //             <br /> {swotSubmission?.stakeholders}
    //             <br />
    //             <br />
    //           </li>
    //         </ul>
    //       </p>
    //     </div>
    //   ) : (
    //     <div>
    //       <div className="mt-6 flex justify-start w-full">
    //         <div className="form-control w-full ">
    //           <label className="label">
    //             <span className="label-text font-bold">
    //               In a society event, who are the stakeholders you must consider
    //               in making ethical decisions, and how do their interests
    //               conflict?
    //             </span>
    //           </label>
    //           <textarea
    //             value={swotSubmission.stakeholders ?? ""}
    //             onChange={(e) =>
    //               setSubmission((prev) => ({
    //                 ...prev,
    //                 stakeholders: e.target.value,
    //               }))
    //             }
    //             className="textarea textarea-sm textarea-bordered h-20"
    //             placeholder="Type here..."
    //           ></textarea>
    //         </div>
    //       </div>

    //       <div className="mt-6 flex justify-start w-full">
    //         <div className="form-control w-full ">
    //           <label className="label">
    //             <span className="label-text font-bold">
    //               How can the choices made by student leaders impact the wider
    //               university community, both positively and negatively?
    //             </span>
    //           </label>
    //           <textarea
    //             value={swotSubmission.choices ?? ""}
    //             onChange={(e) =>
    //               setSubmission((prev) => ({
    //                 ...prev,
    //                 choices: e.target.value,
    //               }))
    //             }
    //             className="textarea textarea-sm textarea-bordered h-20"
    //             placeholder="Type here..."
    //           ></textarea>
    //         </div>
    //       </div>

    //       <div className="mt-6 flex justify-start w-full">
    //         <div className="form-control w-full ">
    //           <label className="label">
    //             <span className="label-text font-bold">
    //               How can the principles of ethical leadership be applied in
    //               student-led societies at LUMS?
    //             </span>
    //           </label>
    //           <textarea
    //             value={swotSubmission.principles ?? ""}
    //             onChange={(e) =>
    //               setSubmission((prev) => ({
    //                 ...prev,
    //                 principles: e.target.value,
    //               }))
    //             }
    //             className="textarea textarea-sm textarea-bordered h-20"
    //             placeholder="Type here..."
    //           ></textarea>
    //         </div>
    //       </div>

    //       <button
    //         className="btn btn-primary mt-8 bg-neutral-50"
    //         onClick={handleStepSubmission}
    //         disabled={status === "Completed"}
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   )}
    // </section>,

    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   {/* Step 1 content */}
    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8 lg:gap-16">
    //       <div className="">
    //         <h2 className="text-xl font-medium sm:text-xl">
    //           <b>Congratulations on successfully navigating Week 6 🥳</b>
    //         </h2>
    //         <p className="mt-4 text-gray-600">
    //           Congratulations on successfully navigating Week 6: Ethical
    //           Leadership and Decision-Making! This week has been an essential
    //           milestone in your leadership development journey, and it's time to
    //           reflect on the insights and skills you've honed:
    //           <br />
    //           Ethical Leadership Foundations: This week, we've reinforced the
    //           bedrock of ethical leadership—how it's not just about making
    //           decisions that are good for business, but also about what's right
    //           for all stakeholders involved. We examined case studies that
    //           illuminated the path from principles to practice.
    //           <br />
    //           The Art of Ethical Decision-Making: We've tackled the complexities
    //           of decision-making, exploring how to balance practical concerns
    //           with ethical standards. You've learned frameworks for making tough
    //           choices that honor your values while considering the impact on
    //           your team, organization, and society.
    //           <br />
    //           Character and Integrity Development: You've taken strides in
    //           strengthening your character and integrity, ensuring they stand
    //           firm even when tested. Through challenging simulations, you've
    //           practiced responding with both courage and conscience.
    //           <br />
    //           Sustainable Leadership Strategies: We delved into the strategies
    //           that make leadership sustainable—not just in environmental terms,
    //           but in maintaining a balance that ensures long-term success and
    //           ethical congruence.
    //           <br />
    //           Reflection on Real-World Challenges: Finally, you've reflected on
    //           real-world challenges and shared your experiences, learning how to
    //           apply ethical frameworks in real-life leadership challenges.
    //           <br />
    //           Bravo on completing Week 6! As we prepare for Week 7, we'll focus
    //           on enhancing your leadership resilience and agility. Continue to
    //           apply the lessons you've learned, stay curious, and remain
    //           committed to your growth as an ethical leader. See you in the next
    //           chapter of your leadership evolution!
    //         </p>

    //         <p className="mt-4 text-gray-600">
    //           Additional Resources for Further Learning:
    //         </p>

    //         <ul className="flex flex-col" style={{ listStyle: "disc" }}>
    //           <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //             The Power of Ethical Management by Norman Vincent Peale, Chapter
    //             5 – Is it right?
    //           </li>
    //           <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //             Meeting the Ethical Challenges of Leadership by Craig E.
    //             Johnson, Chapter 2 – The Ethical Challenges of Leadership
    //           </li>
    //           <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //             In Praise of the Incomplete Leader by Deborah Ancona, Thomas W.
    //             Malone, Wanda J. Orlikowski, and Peter M. Senge
    //           </li>
    //           <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //             The Moral Bias Behind Your Search Results by Andreas Ekstrom
    //           </li>
    //           <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //             <a
    //               target="_blank"
    //               href="https://hbr.org/2007/02/in-praise-of-the-incomplete-leader"
    //               className="link link-primary"
    //             >
    //               In Praise of the Incomplete Leader by Deborah Ancona
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    // // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    // // {/* Step 1 content */}

    // // <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    // //   <div className="grid grid-cols-1 gap-8 lg:gap-16">

    // //     <div className="">
    // //       <h2 className="text-xl font-medium sm:text-xl">
    // //         <b>Congratulations on Completing  Week 6 🥳</b>
    // //       </h2>
    // //       <p className="mt-4 text-gray-600">
    // //       Welcome to Week 6 of your leadership development journey! This week, we immerse ourselves in the critical
    // // theme of "Ethical Leadership and Decision-Making." Explore the intricate world of ethical dilemmas, where every
    // // choice has profound implications for individuals and organizations. Learn about the principles that guide ethical
    // // leaders, the challenging decisions they face, and how these choices shape their leadership legacy. Engage in
    // // dynamic activities, reflective exercises, and insightful discussions to deepen your understanding of ethical
    // // leadership. This week is about honing your ability to navigate complex ethical landscapes with integrity, courage,
    // // and wisdom.
    // //           </p>

    // //       <div className="mt-6 grid grid-cols-1 gap-6">
    // // <div className="space-y-4">

    // // <Collapse accordionData={accordionData7} />

    // // </div>

    // // </div>

    // // <p className="mt-4 text-gray-600">
    // // Great job on completing Week 3! Prepare yourself for Week 4, where we will delve into Conflict
    // // Resolution and its role in leadership. Keep up the outstanding work and continue your journey towards
    // // becoming an extraordinary leader.
    // // Feel free to explore the following resources to help you understand more about yourself.

    // //                 </p>

    // //                 <ul  className="block p-4" style={{listStyle:'circle'}}>

    // //   </ul>

    // //     </div>
    // //   </div>
    // // </div>
    // // </section>

    // // <section
    // //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    // //   style={{ height: "100%" }}
    // // >
    // //   {/* Step 1 content */}
    // //   <h2 className="text-xl font-medium sm:text-xl">
    // //     <b>Case Study: Howard Schultz and Starbucks</b>
    // //   </h2>
    // //   <p className="mt-4 text-gray-600">

    // //   </p>

    // //   <ul
    // //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    // //     style={{ listStyle: "circle" }}
    // //   >
    // //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    // //       <svg
    // //         xmlns="http://www.w3.org/2000/svg"
    // //         fill="none"
    // //         viewBox="0 0 24 24"
    // //         stroke-width="1.5"
    // //         stroke="currentColor"
    // //         className="w-6 h-6"
    // //       >
    // //         <path
    // //           strokeLinecap="round"
    // //           strokeLinejoin="round"
    // //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    // //         />
    // //         <path
    // //           strokeLinecap="round"
    // //           strokeLinejoin="round"
    // //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    // //         />
    // //       </svg>
    // //       Review Peer's SWOT Analysis
    // //     </p>

    // //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    // //     <li className="mx-6">{assignedReview.strengths}</li>

    // //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    // //     <li className="mx-6">{assignedReview.weaknesses}</li>

    // //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    // //     <li className="mx-6">{assignedReview.opportunities}</li>

    // //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    // //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    // //   </ul>

    // //   <div className="mt-6 flex justify-start w-full">
    // //     <div className="form-control w-full ">
    // //       <label className="label">
    // //         <span className="label-text">Feedback</span>
    // //       </label>
    // //       <textarea
    // //         value={swotFeedback}
    // //         className="textarea textarea-sm textarea-bordered h-20"
    // //         placeholder="Type here..."
    // //         onChange={(e) => setFeedback(e.target.value)}
    // //       ></textarea>
    // //     </div>
    // //   </div>

    // //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    // //   disabled={
    // //     assignedReview === ''}

    // //   >
    // //     Submit
    // //   </button>

    // // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Introduction slides";

      case 2:
        return "Understanding Ethical Leadership";

      case 3:
        return "Character and Integrity in Leadership";

      case 4:
        return "Knowledge Check";
      case 5:
        return "Decision-Making Habits";
      case 6:
        return "Transparency & Accountability";
      case 7:
        return "Sustainable leadership";
      case 8:
        return "Ethical leadership challenges";
      case 9:
        return "Practice Activity – Case Study";
      case 10:
        return "Conclusion";

      // case 5:
      //   return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              // disabled={
                              //   (currentStep === 1 && currentImageIndex < 8)  ||
                              //   (currentStep === 3 && currentImageIndex >= 4) ||
                              //   (currentStep === 8 && currentImageIndex >= 3)
                              // }

                              //disabled={!nextBtnCondtion}
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Week6;
